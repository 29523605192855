import React, { Component } from "react";
import styled from "styled-components";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
// import Pagination from '@material-ui/lab/Pagination';
// import PaginationItem from '@mui/material/PaginationItem';
import { Pagination, PaginationItem } from '@material-ui/lab';

import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
import { createStyles,  Theme } from '@material-ui/core/styles';

import { UserService } from "../../service/user.service";

import { ConvertDate } from "../../utility/help";

import { ShareMenu } from "./share_menu";
import { ShareLeft } from "./share_left";

import {
  popupView,
  GetToday,
  ConverDateFull
} from "../../utility/help";

const CustomTableCell = styled(TableCell)`
  color: white;
  padding: 4px;
`;



const MyTextField = styled(TextField)({
    background: "white",
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    // height: 48,
    // padding: '0 30px',
});

  
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

// function Row(props: { row: any }) {
//   const { row } = props;
//   const [open, setOpen] = React.useState(false);
//   const classes = useRowStyles();

//   let ref = "";
//   if (row.ref != null) {
//     ref = row.ref.contents;
//   }

//   return (
//     <React.Fragment>
//       <TableRow className={classes.root} key={row._id}>
//         <CustomTableCell>
//           <IconButton
//             aria-label="expand row"
//             size="small"
//             onClick={() => setOpen(!open)}
//           >
//             {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
//           </IconButton>
//         </CustomTableCell>
//         <CustomTableCell align="center" onClick={() => setOpen(!open)}>
//           {row.title}
//         </CustomTableCell>
//         <CustomTableCell align="center">{ConvertDate(row.row)}</CustomTableCell>
//       </TableRow>
//       <TableRow>
//         <CustomTableCell
//           style={{ paddingBottom: 0, paddingTop: 0 }}
//           colSpan={6}
//         >
//           <Collapse in={open} timeout="auto" unmountOnExit>
//             <Box margin={1}>
//               <div
//                 style={{
//                   height: "400px",
//                   overflowY: "scroll",
//                 }}
//               >
//                 <div dangerouslySetInnerHTML={{ __html: row.contents }}></div>
//               </div>
//             </Box>
//           </Collapse>
//         </CustomTableCell>
//       </TableRow>
//     </React.Fragment>
//   );
// }

interface Props {
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
    page :number;
    bets: any;
    betCount : any;
    selectDay : any
}

export class Bet extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = { bets: [], page : 1, betCount : 1, selectDay :  GetToday().toLocaleString("sv-SE") };
  }

  componentDidMount() {

    this.handleGetBet(this.state.selectDay, 1);
  }

  handleGetBet = (date : any, page : number) => {
    this.userService.get_bets(date, page).then((data: any) => {
      if (data.status === "success") {
        this.setState({ bets: data.bets, betCount : data.betCount, page : page });
      }
    });
  };

  render() {
    let bets = this.state.bets;
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none"
        }}
        
        overlayStyle={{
          overflow: 'scroll'
        }}
      >
        {(close) => (
          
          <div>


<div className="modal-app show">
		<button className="modal-close-btn modal-close" data-dismiss="modal" onClick={() => {
                    this.props.handleClose();
                  }}></button>
		<a href="/" className="diamond-logo">
		   <img className="panel" src="/new/images/panel.png"/>
		   <img className="dias-lg" src="/new/images/diamond-lg.png"/>
		   <div className="dias-sm">
			  <img className="diamond-img" src="/new/images/diamond-sm.png"/>
			  <img className="glow" src="/new/images/glow.png"/>
		   </div>
		   <img className="diamond" src="/new/images/diamond.png"/>
		   <img className="slot" src="/new/images/slot.png"/>
		</a>
	
    <ShareMenu handleChange={this.props.handleChange}></ShareMenu>   
  
	 </div>

	 <div className="modal loginModal subpage-modal fade show" role="dialog" data-backdrop="static" aria-modal="true" style={{paddingRight:'17px',display:'block'}}>
		<div className="modal-dialog modal-dialog-centered">
		   <div className="modal-content">
			  <div className="row">
	

        <ShareLeft handleChange={this.props.handleChange} viewType={popupView.bet}></ShareLeft>



				 <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left">
					<div className="modal-body">
					   <div className="modal-title">
						  <h6 className="title mb-0">배팅내역</h6>
						  <h6 className="sub ml-2 mb-0">Bet</h6>
					   </div>
            
             
                       <MyTextField
                        type="date"
                        defaultValue={this.state.selectDay}
                        
                        onChange={(newValue: any) => {
                            this.setState({selectDay : newValue.target.value})
                            this.handleGetBet(newValue.target.value, 1)
                        }}

                        />

            <div className="form-container">
            <TableContainer component={Paper}>
                        <Table
                          size="small"
                          aria-label="a dense table"
                          style={{ backgroundColor: "#484848" }}
                        >
                          <TableHead>
                            <TableRow>
                              <CustomTableCell align="center">배팅시간</CustomTableCell>
                              <CustomTableCell align="center">배팅금액</CustomTableCell>
                              <CustomTableCell align="center">이긴금액</CustomTableCell>
                              <CustomTableCell align="center">게임업체</CustomTableCell>
                              <CustomTableCell align="center">게임명</CustomTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {bets.map((row: any) => (
                                <TableRow>
                                <CustomTableCell align="center">{ConverDateFull(row.betTime)}</CustomTableCell>
                                <CustomTableCell align="center">{row.bet}</CustomTableCell>
                                <CustomTableCell align="center">{row.win}</CustomTableCell>
                                <CustomTableCell align="center">{row.thirdParty}</CustomTableCell>
                                <CustomTableCell align="center">{row.gameName}</CustomTableCell>
                                </TableRow>
                                ))}
                          </TableBody>
                        </Table>
                      </TableContainer>


            </div>   

             

					</div>

                    <br/>
                    <br/>
                    <Pagination page={this.state.page} count={this.state.betCount} variant="outlined" color="secondary"
                    
                        renderItem={(item)=> <PaginationItem {...item}  style={{color:'white'}} onClick={(e)=>{

                            this.handleGetBet(this.state.selectDay, item.page);

                        }} />}

                    />
				 </div>
			  </div>
		   </div>
		</div>
	 </div>


   <div className="modal-backdrop fade show"></div>

          </div>

        )}
      </Popup>
    );
  }
}
