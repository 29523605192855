import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import
import { BalanceService } from "../../service/balance.service";
import { ShareMenu } from "./share_menu";
import { ShareLeft } from "./share_left";

import {
  popupView,
} from "../../utility/help";


interface Props {
  user: any;

  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  balance: number;
  code: number;
}

export class Withdraw extends Component<Props, State> {
  balanceService = new BalanceService();

  constructor(props: Props) {
    super(props);
    this.state = { balance: 0, code : (new Date()).getTime() };
  }

  handleDoWithdraw = () => {

    if ((Number(this.state.balance) % 10000) !== 0) {
      confirmAlert({
        title: "출금",
        message: "출금은 만원 단위로 가능합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }
    if (Number(this.state.balance) < 30000) {
      confirmAlert({
        title: "출금",
        message: "출금금액을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.balanceService.applyUserWithdrawV2(this.state.balance, this.state.code).then((data) => {
      console.log(data);
      if (data.status === "success") {
        confirmAlert({
          title: "출금",
          message: "출금신청을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else if (data.status === "balance") {
        confirmAlert({
          title: "출금",
          message: "보유중인 금액보다 출금신청금액이 많습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "wait") {
        confirmAlert({
          title: "출금",
          message: "대기중인 출금신청이 있습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "출금",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => window.location.reload()
            },
          ],
        });
      }
    });
  };
  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none"
        }}
        
        overlayStyle={{
          overflow: 'scroll'
        }}
      >
        {(close) => (
          
          <div>


<div className="modal-app show">
		<button className="modal-close-btn modal-close" data-dismiss="modal" onClick={() => {
                    this.props.handleClose();
                  }}></button>
		<a href="/" className="diamond-logo">
		   <img className="panel" src="/new/images/panel.png"/>
		   <img className="dias-lg" src="/new/images/diamond-lg.png"/>
		   <div className="dias-sm">
			  <img className="diamond-img" src="/new/images/diamond-sm.png"/>
			  <img className="glow" src="/new/images/glow.png"/>
		   </div>
		   <img className="diamond" src="/new/images/diamond.png"/>
		   <img className="slot" src="/new/images/slot.png"/>
		</a>
    
    <ShareMenu handleChange={this.props.handleChange}></ShareMenu>   

	 </div>

	 <div className="modal loginModal subpage-modal fade show" role="dialog" data-backdrop="static" aria-modal="true" style={{paddingRight:'17px',display:'block'}}>
		<div className="modal-dialog modal-dialog-centered">
		   <div className="modal-content">
			  <div className="row">
		
    
        <ShareLeft handleChange={this.props.handleChange} viewType={popupView.withdraw}></ShareLeft>



				 <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left">
					<div className="modal-body">
					   <div className="modal-title">
						  <h6 className="title mb-0">출금문의</h6>
						  <h6 className="sub ml-2 mb-0">Money Excharge</h6>
					   </div>
					   
             <p className="mb-md-5 mb-4 text-gray" style={{color: '#ffc107', fontSize:'14px', padding : '0px'}}>
              출금신청은 30.000부터 만원단위로 신청 가능합니다 .<br/>
             </p>
            
             {/* <div className="modal-information mb-md-5 mb-4">
                <button className="mr-3">출금문의</button>
                <span>* 입금시 꼭 계좌문의를 하세요!</span>
             </div> */}
					   <p className="mb-md-5 mb-4 text-gray">
                  23:50 ~ 00:30, 휴일 다음 첫 영업일 새벽에는
                  은행점검으로 인해 계좌이체가 지연될 수 있습니다.
                  <br />위 시간 이외에도 몇몇 은행은 추가적 점검시간이
                  따로 있으니 이점 유념하시기 바랍니다.
            </p>
            
            <div className="form-container">
              <div className="form-group">
                <div className="labels">
                    <span>출금금액</span>
                </div>
                <div className="input-container">
                  <div className="infos">
                    <input className="form-control parsley-success" value={this.state.balance} onChange={(e) => this.setState({balance: Number(e.target.value),})} />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="infos">
                  <div className="btn-grp">
                    <button type="button" onClick={() => { this.setState({ balance: this.state.balance + 30000, }); }}>3만</button>
                    <button type="button" onClick={() => { this.setState({ balance: this.state.balance + 50000, }); }}>5만</button>
                    <button type="button" onClick={() => { this.setState({ balance: this.state.balance + 100000, }); }}>10만</button>
                    <button type="button" onClick={() => { this.setState({ balance: this.state.balance + 500000, }); }}>50만</button>
                    <button type="button" onClick={() => { this.setState({ balance: this.state.balance + 1000000, }); }}>100만</button>
                    <button type="button" onClick={() => { this.setState({ balance: 0, }); }}>Clear</button>
                  </div>
                </div>
              </div> 

              <div className="form-group">
                <div className="labels">
                    <span>은행</span>
                </div>
                <div className="input-container">
                  <div className="infos">
                    {this.props.user.bankname}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="labels">
                    <span>출금자명</span>
                </div>
                <div className="input-container">
                  <div className="infos">
                    {this.props.user.bankowner}
                  </div>
                </div>
              </div>  

              <div className="form-group">
                <div className="labels">
                    <span>계좌번호</span>
                </div>
                <div className="input-container">
                  <div className="infos">
                    {this.props.user.banknum}
                  </div>
                </div>
              </div>                 

            </div>    

            <div className="modal-footer">
              <button type="submit" className="btn-style" onClick={this.handleDoWithdraw}>출금신청</button>
            </div>               

					</div>
				 </div>
			  </div>
		   </div>
		</div>
	 </div>


   <div className="modal-backdrop fade show"></div>

          </div>

        )}
      </Popup>
    );
  }
}
