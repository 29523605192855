import React, { Component } from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import CopyrightIcon from "@material-ui/icons/Copyright";
// const drawerWidth = 440;
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";


import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoodIcon from '@material-ui/icons/Mood';
import DeleteIcon from '@material-ui/icons/Delete';


import { confirmAlert } from "react-confirm-alert"; // Import
import {
  ConvertDate,
  ConverMoeny,
  ConvertBalanceStateToText,
} from "../../utility/help";
import { normalizeUnits } from "moment";
import { UserService } from "../../service/user.service";
import { SlotService } from "../../service/slot.service";

import { Deposit } from "../share/deposit";
import { Withdraw } from "../share/withdraw";
import { Help } from "../share/help";
import { User } from "../share/user";
import { Notie } from "../share/notie";
import { Reg } from "../share/reg";
import { Point } from "../share/point";
import Popup from 'reactjs-popup';
import { BrowserView, MobileView, isMobile } from "react-device-detect";


import { BlueButton, GreenButton, RedButton, } from '../../utility/custom.button';

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
}

const styles = (theme: any) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    color: "#000",
  },

  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    display: "inline",
    padding: "10px",
    fontSize: "16px",
  },
  investing: {
    fontSize: "18px",
  },
});

async function sleep(ms: any) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}


interface Props {
  classes: any;
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;

  //   session: any;
}

interface State {
  ID: string;
  PW: string;
  balance: number;
  postCount: number;
  noteCount: number;
  note: any;
  isOpen: boolean;
  popupStatuses: string;
  expanded: string;
  notes: any;

}
class section extends Component<Props, State> {
  static propTypes: { classes: PropTypes.Validator<object> };
  userService = new UserService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      ID: "",
      PW: "",
      postCount: 0,
      note: [],
      notes: [],
      isOpen: false,
      popupStatuses: popupView.none,
      noteCount: 0,
      expanded: '',


    };


  }

  


  render() {
    

    return (
      <>
      {
         isMobile ? (
            <div  className="banner-section" style={{position:'sticky',    margin: 'auto',     display: 'table' }}>
            <div id="slider">
               <img src="img/m_slideshow1.png" alt="" />
               <img src="img/m_slideshow2.png" alt="" title="Ironman Screenshot" />
            </div>
         </div>
         ) : (
            <div  className="banner-section" style={{position:'sticky',    margin: 'auto',     display: 'table' }}>
            <div id="slider">
               <img src="img/slideshow1.jpg" alt="" />
               <img src="img/slideshow2.jpg" alt="" title="Ironman Screenshot" />
            </div>
         </div >

         )
      }
      

      </>

      
    );
  }
}

section.propTypes = {
  classes: PropTypes.object.isRequired,
};

export let Section = withStyles(styles, { withTheme: true })(section);
export default Section;
